import { Route, RouteProps } from 'react-router-dom';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  return (
    <Route path={props.path}>
      {props.children}
    </Route>
  );
};

export default PrivateRoute;