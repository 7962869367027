import React, { useCallback } from 'react';
import NotificationContext, { NotificationType } from '../../contexts/Notification';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: React.ReactNode;
}

const NotificationProvider: React.FC<Props> = ({ children }: Props) => {
  const showNotification = useCallback(
    (type: NotificationType, message: string, options?: any) => {
      toast[type](message, options);
    },
    []
  );

  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {children}
      <ToastContainer position="bottom-center" hideProgressBar />
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;