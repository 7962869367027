import { useMemo } from 'react';

const useHost = () => {
  const host = useMemo<string>(() => {
    const params = new URLSearchParams(window.location.search);

    // @ts-ignore
    return (params.get('host') as string) || '';
  }, []);

  return host;
}

export default useHost;