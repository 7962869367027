import { useMemo, useEffect } from 'react';

const useShopOrigin = () => {
  const shopOrigin = useMemo<string>(() => {
    const params = new URLSearchParams(window.location.search);

    // @ts-ignore
    return (params.get('shop') as string) || window.APD_shopOrigin || '';
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.APD_shopOrigin = shopOrigin
  }, [shopOrigin])

  return shopOrigin;
}

export default useShopOrigin;