import React from 'react';
import { Spinner } from '@shopify/polaris';

interface Props {
  message?: string
}

const FullPageLoading: React.FC<Props> = ({ message }) => {
  return (
    <div className="fixed left-0 top-0 bottom-0 right-0 flex justify-center items-center">
      <div className="flex justify-center items-center flex-col">
        {message && <div className="text-3xl">{message}</div>}
        <Spinner size="large" />
      </div>
    </div>
  )
};

export default FullPageLoading;
